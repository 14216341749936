<template>
	<section
		v-if="isLoaded"
		class="section wow"
	>
		<div class="content">
			<div class="university-registration-block">
				<h3 class="block-title">
					Регистрация наставника
				</h3>
				<div class="text">
					<p>Приглашаем сотрудников активов АФК “Система” стать наставниками для талантливых студентов!</p>
				</div>
				<form
					class="form"
					novalidate
					@submit.prevent="registration"
				>
					<div class="field-wrap">
						<label
							class="field-label"
						>
							<input
								v-model.trim="$v.regForm.full_name.$model"
								:disabled="isLogged"
								type="text"
								class="field"
								placeholder="ФИО"
								style="text-transform: capitalize"
							>
							<div class="label">ФИО</div>
							<span
								v-if="$v.regForm.full_name.$error"
								class="help-block form-error"
							>
								{{
									!$v.regForm.full_name.required ? "Это обязательное поле" : $v.regForm.full_name.alphaSpace ? "Только буквы, пробел и дефис" : "Введите корректное ФИО"
								}}
							</span>
						</label>
					</div>
					<div class="field-wrap">
						<label class="field-label">
							<input
								v-model.trim="$v.regForm.post.$model"
								type="text"
								class="field"
								placeholder="Должность"
							>
							<div class="label">Должность</div>
							<span
								v-if="$v.regForm.post.$error"
								class="help-block form-error"
							>Это обязательное поле</span>
						</label>
					</div>
					<div class="field-wrap">
						<label class="field-label">
							<input
								v-model.trim="$v.regForm.work_place.$model"
								type="text"
								class="field"
								placeholder="Наименование организации"
							>
							<div class="label">Наименование организации</div>
							<span
								v-if="$v.regForm.work_place.$error"
								class="help-block form-error"
							>Это обязательное поле</span>
						</label>
					</div>
					<div class="field-wrap">
						<label
							class="field-label"
						>
							<input
								v-model.trim="$v.regForm.city.name.$model"
								type="text"
								class="field"
								placeholder="Город"
							>
							<div class="label">Город</div>
							<span
								v-if="$v.regForm.city.$error"
								class="help-block form-error"
							>Это обязательное поле</span>
						</label>
					</div>
					<div class="field-wrap">
						<label
							class="field-label"
						>
							<input
								v-model.trim="$v.regForm.email.$model"
								:disabled="isLogged"
								type="text"
								class="field"
								placeholder="Email"
								@blur="checkEmail"
							>
							<div class="label">Email</div>
							<span
								v-if="$v.regForm.email.$error"
								class="help-block form-error"
							>
								{{
									!$v.regForm.email.required ? "Это обязательное поле" : !$v.regForm.email.email ? "Введите корректный e-mail" : "Ошибка поля"
								}}
							</span>
						</label>
					</div>
					<transition name="fade">
						<div
							v-if="!isLogged"
							class="field-wrap"
						>
							<label class="field-label">
								<input
									v-model.trim="$v.pass.$model"
									type="password"
									class="field"
									placeholder="Пароль"
								>
								<div class="label">Пароль</div>
								<span
									v-if="$v.pass.$error"
									class="help-block form-error"
								>
									{{ passErrorText }}
								</span>
							</label>
						</div>
					</transition>
					<transition name="fade">
						<div
							v-if="isShowConfPass"
							class="field-wrap"
						>
							<label
								class="field-label"
							>
								<input
									v-model.trim="$v.confPass.$model"
									type="password"
									class="field"
									placeholder="Повторите пароль"
								>
								<div class="label">Повторите пароль</div>
								<span
									v-if="$v.confPass.$error"
									class="help-block form-error"
								>
									{{ confPassErrorText }}
								</span>
							</label>
						</div>
					</transition>
					<div class="field-wrap sm">
						<label class="checkbox-filter">
							<input
								v-model="$v.isPersonal.$model"
								type="checkbox"
								@change="$v.isPersonal.$touch()"
							>
							<span class="info">Соглашаюсь с условиями обработки <a
								href="/privacy-policy"
								target="_blank"
							>персональных данных</a></span>
							<div
								v-if="$v.isPersonal.$error"
								class="help-block form-error"
							>Необходимо подтверждение
							</div>
						</label>
					</div>
					<div class="field-wrap">
						<label class="checkbox-filter">
							<input
								v-model="$v.isAgree.$model"
								type="checkbox"
								@change="$v.isAgree.$touch()"
							>
							<span class="info">Соглашаюсь с условиями <a
								href="/agreement"
								target="_blank"
							>пользовательского соглашения</a></span>
							<div
								v-if="$v.isAgree.$error"
								class="help-block form-error"
							>Необходимо подтверждение
							</div>
						</label>
					</div>
					<button
						v-if="!isSended"
						id="btnRegin_14"
						class="button orange lg btnRegistration"
					>
						Зарегистрироваться
					</button>
					<div
						v-else
						class="button orange lg button-preloader"
					>
						<div class="button-preloader-icons">
							<div class="button-preloader-icon" />
							<div class="button-preloader-icon" />
							<div class="button-preloader-icon" />
						</div>
					</div>
				</form>
				<br>
				<br>
				<div class="text md">
					<p>
						Зарегистрировавшись, Вы автоматически становитесь гостем платформы «Лифт в будущее». <br>Здесь
						Вы
						можете найти профориентационные тесты и курсы, развивающие <br>навыки самопрезентации и другие
						надпрофессиональные навыки.
					</p>
				</div>
			</div>
		</div>
	</section>
</template>

<script>

import { email, minLength, required, requiredIf, sameAs } from 'vuelidate/lib/validators';
import { alphaNumber, alphaSpace } from '@/assets/scripts/custom_validators';
import { mapActions, mapGetters } from 'vuex';
import User from '@/api/user';

export default {
	data: () => ({
		isPersonal: false,
		isAgree: false,
		isSended: false,
		isCityClear: false,
		isUniversityClear: false,
		isShowConfPass: false,
		successLocationUrl: '',
		regForm: {
			full_name: '',
			post: '',
			city: {
				name: ''
			},
			user_role: 'Specialist',
			email: '',
			work_place: '',
			study: {
				education: 'Высшее'
			},
			external_service: {
				slug: 'mentorsapplication-2'
			}
		},
		pass: '',
		confPass: ''
	}),
	validations() {
		return {
			regForm: {
				full_name: {
					required,
					alphaSpace
				},
				work_place: {
					required
				},
				study: {
					education: {
						required
					}
				},
				city: {
					name: { required }
				},
				user_role: {
					required
				},
				email: {
					required,
					email
				},
				post: { required }
			},
			isPersonal: {
				sameAs: sameAs(() => true)
			},
			isAgree: {
				sameAs: sameAs(() => true)
			},
			pass: {
				required: requiredIf(function() {
					return !this.isLogged;
				}),
				minLength: minLength(8),
				alphaNumber
			},
			confPass: {
				required: requiredIf(function() {
					return this.isShowConfPass;
				}),
				sameAsPassword: (function() {
					if (this.isShowConfPass) {
						return this.pass === this.confPass;
					}
					return true;
				})
			}
		};
	},
	computed: {
		...mapGetters('user', {
			isLogged: 'isLoggedIn',
			user: 'userInfo'
		}),
		...mapGetters('modal', {
			isActiveModal: 'isActive'
		}),
		isLoaded() {
			if (!this.isLogged) {
				return true;
			}
			return !!(this.isLogged && this.user);
		},
		passErrorText() {
			if (!this.$v.pass.required) {
				return 'Это обязательное поле';
			}

			if (!this.$v.pass.alphaNumber || !this.$v.pass.minLength) {
				return `Пароль должен состоять из букв и цифр, не менее ${ this.$v.pass.$params.minLength.min } символов`;
			}

			return 'Ошибка поля';
		},
		confPassErrorText() {
			if (!this.$v.confPass.required) {
				return 'Это обязательное поле';
			}

			if (!this.$v.confPass.sameAsPassword) {
				return 'Пароли не совпадают';
			}

			return 'Ошибка поля';
		}
	},
	watch: {
		user() {
			this.setDefaultUserInfo();
		},

		isActiveModal(value) {
			if (!value && this.successLocationUrl) {
				window.location.href = this.successLocationUrl;
			}
		}
	},
	created() {
		this.setDefaultUserInfo();
	},
	methods: {
		...mapActions('user', {
			loginEvent: 'loginEvent'
		}),
		selectCity(val) {
			this.regForm.city.name = val;
		},
		setDefaultUserInfo() {
			if (this.user) {
				const firstName = this.user.first_name || '';
				const lastName = this.user.last_name || '';
				const middleName = this.user.middle_name || '';
				this.regForm.full_name = `${ lastName } ${ firstName } ${ middleName }`.trim();
				this.regForm.email = this.user.email;
				this.regForm.city.name = this.user.city?.name || '';
			}
		},
		registration() {
			this.$v.$touch();
			if (this.$v.$invalid) {
				console.log('Error in Reg form');
				return;
			}
			if (!this.isSended) {
				this.isSended = true;
				const [last_name, first_name, middle_name] = this.regForm.full_name.split(' ');
				const data = {
					last_name,
					first_name,
					middle_name,
					...this.regForm
				};

				if (!this.isLogged) {
					data.password = this.pass;
				}

				this.loginEvent({
					data,
					slug: 'mentorsapplication-2'
				})
					.then((data) => {
						this.successLocationUrl = data.path ? data.path : window.location.origin;
						this.modalOpen('mentorsapplicationSuccess');
					})
					.catch(error => this.modalOpen('eventFail', error))
					.finally(() => {
						this.isSended = false;
						this.isCityClear = false;
						this.isUniversityClear = false;
					});
			}
		},
		async checkEmail() {
			try {
				if (this.regForm.email) {
					await User.checkEmail(this.regForm.email);
				}
				this.isShowConfPass = false;
			} catch {
				this.isShowConfPass = true;
			}
		}
	}
};
</script>

<style>
body {
	display: block;
}
</style>
